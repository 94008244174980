<template>
  <div class="about-container">
    <img :src="imageSrc" alt="Wormble" class="title-image" @click="goToHomePage">
    <h1>About Wormble</h1>
    <p>This is a simple fun tool to create a 3x3 scramble out of a word. The scrambles are generated using an in house algorithm that is based on random moves, not random state. This means scramble integrity will NOT be as high as competition scrambles, though for our purpose - fun and sharing - it does the job nicely. <br> <br>If you want to support the creator "swiftcubing" then please visit some of the links to Youtube / Instagram / Cubicle below.</p>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
  data() {
    return {
      imageSrc: require('@/assets/WormbleRe.png'),
    };
  },
  methods: {
    goToHomePage() {
      this.$router.push({ name: 'ConvertForm' });
    },
  },
};
</script>

<style scoped>
.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.title-image {
  width: 80%;
  height: auto;
  max-width: 400px;
  margin-bottom: 20px;
  cursor: pointer; /* Add cursor pointer to indicate clickable image */
}

h1 {
  color: #FF5733; /* Bright orange color */
  margin-bottom: 20px;
}

p {
  color: #ffb18e; /* Lighter orange color */
  font-size: 1.1em;
  text-align: center;
  max-width: 600px;
}
</style>
