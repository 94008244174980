<template>
  <div v-if="currentAdvert" class="advert" :style="{ backgroundColor: backgroundColor }">
    <a :href="currentAdvert.link" target="_blank">{{ currentAdvert.text }}</a>
  </div>
</template>

<script>
import adverts from '@/assets/advertsForBanner.json';

export default {
  data() {
    return {
      adverts: [],
      currentAdvertIndex: 0,
      currentAdvert: null,
      backgroundColor: '#ffb18e'
    };
  },
  mounted() {
    this.adverts = adverts;
    setTimeout(this.showNextAdvert, 7000);
  },
  methods: {
    showNextAdvert() {
      this.currentAdvertIndex = (this.currentAdvertIndex + 1) % this.adverts.length;
      this.currentAdvert = this.adverts[this.currentAdvertIndex];
      setTimeout(this.showNextAdvert, 7000);
    }
  }
};
</script>

<style scoped>
.advert {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  color: #202020;
  text-align: center;
  z-index: 1000;
}

.advert a {
  color: inherit;
  text-decoration: none; /* Remove underline for a cleaner look */
}

.advert a:hover {
  /* Add hover styles for the link (e.g., change color) */
  color: rgb(255, 228, 228); /* Example hover effect */
}


</style>