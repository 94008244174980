<template>
  <div id="app">
    <MenuComponent @toggleTimer="toggleTimer" />
    <router-view :timerEnabled="timerEnabled" />
    <AdvertBanner />
  </div>
</template>

<script>
import MenuComponent from './components/MenuComponent.vue';
import AdvertBanner from './components/AdvertBanner.vue';


export default {
  name: 'App',
  components: {
    MenuComponent,
    AdvertBanner,
  },
  data() {
    return {
      showAbout: false,
      timerEnabled: localStorage.getItem('timerEnabled') === 'true'
    };
  },
  methods: {
    showAboutPage() {
      this.showAbout = true;
    },
    toggleTimer(timerEnabled) {
      this.timerEnabled = timerEnabled;
    },
  },
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-image: url('@/assets/diamondsun.svg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 100vh;
  overflow-y: auto;
}

#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  box-sizing: border-box;
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  #app {
    padding: 50px 20px 20px;
    align-items: center;
    justify-content: flex-start;
  }
}
</style>
