// src/axios.js
import axios from 'axios';

// Use an environment variable to set the base URL
const baseURL = process.env.VUE_APP_BASE_URL || 'http://localhost:3000'; // Default to localhost if the environment variable is not set

const instance = axios.create({
  baseURL,
});

export default instance;
