<template>
  <div class="menu-container">
    <button @click="toggleMenu" class="menu-button">
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 34.5 34.5"
        xml:space="preserve"
        class="cog-icon"
      >
        <g>
          <path
            d="M34.5,20.375v-6.25h-4.514c-0.32-1.313-0.838-2.545-1.521-3.669l3.193-3.193l-4.42-4.419l-3.193,3.193
                c-1.125-0.683-2.355-1.2-3.67-1.521V0.001h-6.25v4.515c-1.313,0.321-2.546,0.838-3.671,1.521L7.262,2.844L2.843,7.263l3.193,3.193
                c-0.684,1.125-1.2,2.357-1.521,3.669H0v6.25h4.516c0.32,1.312,0.838,2.545,1.521,3.669l-3.193,3.191l4.419,4.421l3.192-3.193
                c1.125,0.685,2.357,1.2,3.671,1.521v4.516h6.25v-4.516c1.313-0.32,2.545-0.838,3.67-1.521l3.192,3.193l4.421-4.421l-3.193-3.191
                c0.685-1.125,1.199-2.355,1.521-3.669H34.5z M17.25,23.5c-3.451,0-6.25-2.798-6.25-6.25S13.799,11,17.25,11s6.25,2.798,6.25,6.25
                S20.701,23.5,17.25,23.5z"
            fill="#ffb18e"
          />
        </g>
      </svg>
    </button>
    <div v-if="menuOpen" class="menu">
      <ul>
        <li @click="showAboutPage" style="border-bottom: 1px solid orange;">About</li>
        <li v-if="showTimerOption">
          <label>Timer</label>
          <input type="checkbox" v-model="timerEnabled" @change="saveSettings" role="switch" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuComponent',
  data() {
    return {
      menuOpen: false,
      timerEnabled: localStorage.getItem('timerEnabled') === 'true', // Initialize from local storage
      showTimerOption: true, // Flag to control whether the timer option is shown
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    showAboutPage() {
      console.log('Navigating to About Page');
      this.$emit('showAbout'); // Check if this is necessary
      this.$router.push({ name: 'AboutPage' });
//this.$router.push('/about');
  this.menuOpen = false;
  },
    saveSettings() {
      localStorage.setItem('timerEnabled', this.timerEnabled); // Save selection to local storage
      this.$emit('toggleTimer', this.timerEnabled);
    },
    disableTimerOption() {
      this.showTimerOption = false; // Disable the timer option
    },
    enableTimerOption() {
      this.showTimerOption = true; // Enable the timer option
    },
  },
};
</script>

<style scoped>
.menu-container {
  position: fixed;
  top: 10px;
  right: 10px;
}

.menu-button {
  background: none;
  border: none;
  cursor: pointer;
}

.cog-icon {
  width: 30px;
  height: 30px;
}

.menu {
  position: absolute;
  top: 40px;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 120px; /* Set a specific width for the menu */
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  margin: 10px 0;
  cursor: pointer;
}

label {
  margin-right: 10px; /* Add space between label and switch */
}

/* Styling for the toggle switch */
input:where([type="checkbox"][role="switch"]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  color: inherit;
  font-size: inherit;
  width: 2em;
  height: 1em;
  box-sizing: content-box;
  border: 1px solid;
  border-radius: 1em;
  vertical-align: text-bottom;
  margin: auto;
  background-color: #ccc;
  transition: background-color 0.3s;
}

input:where([type="checkbox"][role="switch"])::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  box-sizing: border-box;
  width: 0.7em;
  height: 0.7em;
  margin: 0 0.15em;
  border: 1px solid;
  border-radius: 50%;
  background: #fff;
  transition: left 0.3s;
}

input:where([type="checkbox"][role="switch"]):checked {
  background-color: #ffb18e;
}

input:where([type="checkbox"][role="switch"]):checked::before {
  left: 1em;
}

input:where([type="checkbox"][role="switch"]):disabled {
  opacity: 0.4;
}
</style>

